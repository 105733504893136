import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import {
    Modal,
    Button
} from 'react-bootstrap'
import { getTestingRecent, updateMetricGoal } from '../../../crud/profile.crud'
import {
    TextField
} from '@material-ui/core'
import Loader from '../../../components/Loader'
import Logout from '../../auth/Logout'
import DatePicker from '../../../components/DatePicker'
import moment from 'moment'
import TimePicker from '../../../components/TimePicker';

export default ({ modal, setModal, label, id, metric, loadData, category, check, setCheck }) => {

    const dispatch = useDispatch();

    const [input, setInput] = useState([])
    const [data, setData] = useState([])
    const [logout, setLogout] = useState(null)
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (metric && check[0] && modal) {
            setLoader(true)
            getTestingRecent(id, { id: check[0], metric: metric })
                .then(res => {
                    setInput(res.data.data.goal.map(item => item.name === 'goalDate' ? { ...item, value: moment(item.value, 'YYYY-MM-DD').format('MM/DD/YYYY') } : item))
                    setLoader(false)
                })
                .catch(({ response }) => {
                    setLoader(false)
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    }
                })
        }
    }, [modal])  //eslint-disable-line

    useEffect(() => {
        input.map(item => {
            return item.name !== 'id'
                ? setData(data => ({ ...data, [item.name]: item.value }))
                : null
        })
    }, [input])

    const submit = () => {
        setLoader(true)
        setErrors({})
        if (data.target_plank_time && typeof data.target_plank_time !== 'number') {
            data.target_plank_time = timeToSeconds(data.target_plank_time)
        }
        updateMetricGoal(id, check[0], data)
            .then(res => {
                dispatch(AlertState.actions.refreshStatistic())
                setLoader(false)
                setModal(false)
                loadData()
                setCheck([])
                dispatch(AlertState.actions.alert({
                    text: 'Goal data is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    Object.keys(response.data.errors).map(item => { // eslint-disable-line
                        if (item !== 'basicData') setErrors({ ...errors, [item]: response.data.errors[item][0] })
                    })
                response &&
                    response.data &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
                setLoader(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                }
            })
    }

    const secondsToTime = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substring(11, 19);
        return timeString
    }

    const timeToSeconds = (time) => {
        var a = time.split(':');
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
    }

    return (
        <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loader visible={loader} />
                {logout}
                {
                    input.map((item, index) => {
                        return item.name !== 'id' && (item.name === 'goalDate'
                            ? (
                                <DatePicker
                                    key={index}
                                    format={'MM/DD/YYYY'}
                                    label={item.label}
                                    value={data[item.name] || null}
                                    future={true}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            [item.name]: e
                                        })
                                    }}
                                    style={{
                                        fontWeight: 300,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    }}
                                    helperText={errors[`${category}.${metric}`]}
                                    error={Boolean(errors[`${category}.${metric}`])}
                                />
                            ) : item.name === 'target_plank_time' ? (
                                <TimePicker
                                    onChange={value => {
                                        setData({
                                            ...data,
                                            [item.name]: value
                                        })
                                    }}
                                    value={!data[item.name]
                                        ? '00.00.00'
                                        : typeof data[item.name] === 'number'
                                            ? secondsToTime(data[item.name])
                                            : data[item.name]
                                    }
                                />
                            ) : (
                                <TextField
                                    variant="outlined"
                                    key={index}
                                    type="text"
                                    className='textField'
                                    name={item.name}
                                    label={item.label}
                                    value={data[item.name] || ''}
                                    onChange={e => {
                                        setData({
                                            ...data,
                                            [item.name]: e.target.value
                                        })
                                    }}
                                    margin="normal"
                                    helperText={errors[`${category}.${metric}`]}
                                    error={Boolean(errors[`${category}.${metric}`])}
                                />
                            ))
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(false)}>
                    Close
                </Button>
                <Button variant="primary" className="btn-blue" onClick={submit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}